/* Image Hover Animation */

.darken {
    -webkit-filter: brightness(100%);
}

.darken:hover {
    -webkit-filter: brightness(50%);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
}