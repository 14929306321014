.option-container {
    padding: 7px 6px 8px 6px;
}

.option-container:hover {
    background-color: rgb(240, 240, 240);
}

.radio-container {
    padding: 10px;
}

.radio-button {
    width: 15px;
    height: 15px;
    border-width: 2px;
    border-radius: 50%;
    display: inline-flex; /* !!! */
    justify-content: center;
    align-items: center;
}

.radio-button .circle {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    opacity: 0;
    transition-duration: 200ms;
}

.radio-button input {
    display: none;
}

.radio-button input:checked + .circle {
    opacity: 1;
}